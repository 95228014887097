<template>
  <div class="flex">
    <TempLeftNav />
    <div>This is the Carousel component</div>
  </div>
</template>

<script>
import TempLeftNav from "../TempLeftNav";
export default {
  name: "Carousel",
  components: {
    TempLeftNav,
  },
};
</script>

<style></style>
